<template>
  <div class="bg-white rounded-5 p-25">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <AppIcon
          name="notifications"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Notifications</p>
      </div>

      <div class="flex items-end">
        <AppButton 
          type="primary" 
          plain 
          class="px-30 py-15 mr-15" 
          :disabled="!notificationsList.length"
          @click="onExport"
        >
          Export in CSV
        </AppButton>

        <AppButton
          type="primary"
          class="mr-10 px-30"
          icon-size="14"
          icon="plus"
          @click="router.push({ name: routesNames.adminNotificationsCreate })"
        >
          Add New Notification
        </AppButton>
      </div>
    </div>

    <div class="mt-20">
      <AppTable
        :dataset="notificationsList"
        :headers="tableHeaders"
        class="notifications-table"
        rowHeight="50px"
        type="admin"
        theme="primary"
        show-actions
        @sort-by="handleSort"
      >
        <!-- R O W S -->

        <template #createdAt="{ row }">
          {{ $filters.date(row.createdAt, 'MM/DD/YYYY  hh:mm A') }}
        </template>

        <!-- A C T I O N S -->
        <template #actions="{ row, handleHidePopper }">
          <ul class="actions-wrapper py-10">
            <li
              v-for="(action, i) in actions"
              :key="i"
              class="action flex items-center cursor-pointer py-7 px-10"
              :class="action.customClass"
              @click="action.handler(row, handleHidePopper)"
            >
              <AppIcon
                :name="action.icon"
                size="12"
                class="mr-10"
              />
              <span>{{ action.title }}</span>
            </li>
          </ul>
        </template>
      </AppTable>
    </div>

    <!-- P A G I N A T I O N -->
    <div
      v-if="notificationsList.length"
      class="flex justify-between items-center pt-20 border-t border-grey-fp-10"
    >
      <PaginationCounter
        :offset="offset"
        :limit="NOTIFICATIONS_PAGINATION_LIMIT"
        :total="notificationsTotal"
        class="text-posey-blue"
      />

      <v-pagination
        :page-size="NOTIFICATIONS_PAGINATION_LIMIT"
        layout="prev, pager, next"
        hide-on-single-page
        :total="notificationsTotal"
        @current-change="onCurrentPageChanged"
      />
    </div>
  </div>
  <!-- D E L E T E  C O N F I R M A T I O N -->
  <portal
    v-if="isOpenConfirmDeleteModal"
    to="admin"
  >
    <AppModal @cancel="discardDelete">
      <div class="w-full min-w-280 max-w-400">
        <p class="text-dark-cl-20 text-md mb-8">Delete Notification</p>
        <p class="text-grey-fp-60 mb-40">Are you sure you want to delete this notification?</p>
        <div class="flex items-center justify-end">
          <AppButton
            type="primary"
            size="mini"
            class="px-20 mr-10"
            @click="discardDelete"
          >
            Close
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="confirmDelete"
          >
            Yes
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeMount, reactive, toRefs } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { routesNames, router } from '@/router';
  import { INotificationRes } from '@/types';
  import { vuex } from '@/store';
  import { notificationsService } from '@/services';
  import { notifications, NOTIFICATIONS_PAGINATION_LIMIT } from './api-request-params';
  import { downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'Notifications',

    components: { AppButton, AppIcon, AppModal, AppTable, PaginationCounter },

    setup() {
      const toast = useToast();
      const state = reactive({ 
        notificationsList: [] as INotificationRes[], 
        sortListBy: [] as Array<string[]>,
        notificationsTotal: 0 as number,
      });
      let isOpenConfirmDeleteModal = ref<boolean>(false);
      let deleteNotificationId = ref<number | string>('');
      const currentPage = ref<number>(0);

      const { notificationsList, notificationsTotal } = toRefs(state);

      const tableHeaders = computed(() => [
        { property: 'description', label: 'Description', sortable: true, minWidth: 500 },
        { property: 'createdAt', label: 'Publication date', sortable: true, minWidth: 180 }
      ]);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: onEdit, customClass: 'text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: openConfirmationModal, customClass: 'text-error' },
      ]);

      const offset = computed<number>(() => {
        return currentPage.value * NOTIFICATIONS_PAGINATION_LIMIT;
      });

      function openConfirmationModal({ id }: INotificationRes, hideTooltipCb: any) {
        isOpenConfirmDeleteModal.value = true;
        deleteNotificationId.value = id;
        hideTooltipCb();
      }

      function discardDelete() {
        closeConfirmationModal();
        deleteNotificationId.value = '';
      }

      function onEdit({ id }: INotificationRes) {
        router.push({ name: routesNames.adminNotificationsUpdate, query: { id }});
      }

      function closeConfirmationModal() {
        isOpenConfirmDeleteModal.value = false;
      }

      function onCurrentPageChanged(page: number) {
        currentPage.value = page - 1;

        fetchNotifications();
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          if (orderBy === 'category') {
            state.sortListBy = [['category', 'name', order]];
          } else {
            state.sortListBy = [[orderBy, order]];
          }
        } else {
          state.sortListBy = [];
        }

        fetchNotifications();
      }


      async function confirmDelete() {
        closeConfirmationModal();
        vuex.setLoading(true);

        return notificationsService.deleteNotification(deleteNotificationId.value as number)
          .then(() => {
            toast.success('Notification has been deleted');
            vuex.setLoading(false);
            if (notificationsList.value.length === 1) {
              if (currentPage.value >= 1) {
                currentPage.value -= 1;
              }
              currentPage.value = 0;
            }
            return fetchNotifications();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function fetchNotifications() {
        vuex.setLoading(true);

        return await notificationsService.fetchNotifications(notifications(offset.value, state.sortListBy))
          .then((res: any) => { 
            notificationsList.value = res.data;
            notificationsTotal.value = res.totalCount; 
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return notificationsService.downloadNotifications(state.sortListBy)
          .then((res: any) => {
            downloadHelper(res, 'Notifications', 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => {
        fetchNotifications();
      });

      return {
        notificationsList,
        routesNames,
        router,
        tableHeaders,
        actions,
        isOpenConfirmDeleteModal,
        NOTIFICATIONS_PAGINATION_LIMIT,
        currentPage,
        notificationsTotal,
        offset,

        onCurrentPageChanged,
        discardDelete,
        confirmDelete,
        onExport,
        handleSort
      };
    }

  });
</script>

<style lang="scss" scoped>
  .notifications-table {
    max-height: 500px;
  }
</style>