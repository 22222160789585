
  import { defineComponent, computed, ref, onBeforeMount, reactive, toRefs } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { routesNames, router } from '@/router';
  import { INotificationRes } from '@/types';
  import { vuex } from '@/store';
  import { notificationsService } from '@/services';
  import { notifications, NOTIFICATIONS_PAGINATION_LIMIT } from './api-request-params';
  import { downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'Notifications',

    components: { AppButton, AppIcon, AppModal, AppTable, PaginationCounter },

    setup() {
      const toast = useToast();
      const state = reactive({ 
        notificationsList: [] as INotificationRes[], 
        sortListBy: [] as Array<string[]>,
        notificationsTotal: 0 as number,
      });
      let isOpenConfirmDeleteModal = ref<boolean>(false);
      let deleteNotificationId = ref<number | string>('');
      const currentPage = ref<number>(0);

      const { notificationsList, notificationsTotal } = toRefs(state);

      const tableHeaders = computed(() => [
        { property: 'description', label: 'Description', sortable: true, minWidth: 500 },
        { property: 'createdAt', label: 'Publication date', sortable: true, minWidth: 180 }
      ]);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: onEdit, customClass: 'text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: openConfirmationModal, customClass: 'text-error' },
      ]);

      const offset = computed<number>(() => {
        return currentPage.value * NOTIFICATIONS_PAGINATION_LIMIT;
      });

      function openConfirmationModal({ id }: INotificationRes, hideTooltipCb: any) {
        isOpenConfirmDeleteModal.value = true;
        deleteNotificationId.value = id;
        hideTooltipCb();
      }

      function discardDelete() {
        closeConfirmationModal();
        deleteNotificationId.value = '';
      }

      function onEdit({ id }: INotificationRes) {
        router.push({ name: routesNames.adminNotificationsUpdate, query: { id }});
      }

      function closeConfirmationModal() {
        isOpenConfirmDeleteModal.value = false;
      }

      function onCurrentPageChanged(page: number) {
        currentPage.value = page - 1;

        fetchNotifications();
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          if (orderBy === 'category') {
            state.sortListBy = [['category', 'name', order]];
          } else {
            state.sortListBy = [[orderBy, order]];
          }
        } else {
          state.sortListBy = [];
        }

        fetchNotifications();
      }


      async function confirmDelete() {
        closeConfirmationModal();
        vuex.setLoading(true);

        return notificationsService.deleteNotification(deleteNotificationId.value as number)
          .then(() => {
            toast.success('Notification has been deleted');
            vuex.setLoading(false);
            if (notificationsList.value.length === 1) {
              if (currentPage.value >= 1) {
                currentPage.value -= 1;
              }
              currentPage.value = 0;
            }
            return fetchNotifications();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function fetchNotifications() {
        vuex.setLoading(true);

        return await notificationsService.fetchNotifications(notifications(offset.value, state.sortListBy))
          .then((res: any) => { 
            notificationsList.value = res.data;
            notificationsTotal.value = res.totalCount; 
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return notificationsService.downloadNotifications(state.sortListBy)
          .then((res: any) => {
            downloadHelper(res, 'Notifications', 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => {
        fetchNotifications();
      });

      return {
        notificationsList,
        routesNames,
        router,
        tableHeaders,
        actions,
        isOpenConfirmDeleteModal,
        NOTIFICATIONS_PAGINATION_LIMIT,
        currentPage,
        notificationsTotal,
        offset,

        onCurrentPageChanged,
        discardDelete,
        confirmDelete,
        onExport,
        handleSort
      };
    }

  });
