import { IRequestParams } from '@/types';

export const NOTIFICATIONS_PAGINATION_LIMIT = 10;

export const notifications = (offset: number, order?: Array<string[]>): IRequestParams => ({
  params: {
    filter: {
      limit: NOTIFICATIONS_PAGINATION_LIMIT,
      offset,
      order: order?.length ? order : [ ["createdAt", "DESC"] ],
    },
    includeCount: true
  }
});